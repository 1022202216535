import Blockly from 'blockly';

const REPEAT_COLOR = '#297FD5';
const IF_COLOR = '#7030A0';
const SIMPLE_BLOCKS_COLOR = '#7F8FA9';

Blockly.Blocks['robovac_repeat_until_clean'] = {
    init: function() {
        this.setPreviousStatement(true);
        this.appendDummyInput()
            .appendField('wiederhole bis alles sauber');
        this.appendStatementInput('DO');
        this.setColour(REPEAT_COLOR);
    }
};

Blockly.Blocks['robovac_repeat'] = {
    init: function() {
        this.setPreviousStatement(true);
        this.appendDummyInput()
            .appendField('wiederhole')
            .appendField(new Blockly.FieldDropdown([
                ['bis', 'UNTIL'],
                ['solange', 'WHILE']
            ]), 'REPEAT_KIND')
            .appendField(new Blockly.FieldDropdown([
                ['vorne', 'IN_FRONT'],
                ['links', 'ON_THE_LEFT'],
                ['rechts', 'ON_THE_RIGHT']
            ]), 'DIRECTION')
            .appendField(new Blockly.FieldDropdown([
                ['Wand', 'WALL'],
                ['keine Wand', 'NO_WALL'],
                ['dreckiges Feld', 'DIRTY_SQUARE'],
                ['sauberes Feld', 'CLEAN_SQUARE']
            ]), 'CONDITION');
        this.appendStatementInput('DO');
        this.setNextStatement(true);
        this.setColour(REPEAT_COLOR);
    }
};

Blockly.Blocks['robovac_repeat_times'] = {
    init: function() {
        this.setPreviousStatement(true);
        this.appendDummyInput()
            .appendField('wiederhole')
            .appendField(new Blockly.FieldNumber(1, 1, 100), 'REPETITIONS')
            .appendField('mal');
        this.appendStatementInput('DO');
        this.setNextStatement(true);
        this.setColour(REPEAT_COLOR);
    }
};

Blockly.Blocks['robovac_if'] = {
    init: function() {
        this.setPreviousStatement(true);
        this.appendDummyInput()
            .appendField('falls')
            .appendField(new Blockly.FieldDropdown([
                ['vorne', 'IN_FRONT'],
                ['links', 'ON_THE_LEFT'],
                ['rechts', 'ON_THE_RIGHT']
            ]), 'DIRECTION')
            .appendField(new Blockly.FieldDropdown([
                ['Wand', 'WALL'],
                ['keine Wand', 'NO_WALL'],
                ['dreckiges Feld', 'DIRTY_SQUARE'],
                ['sauberes Feld', 'CLEAN_SQUARE']
            ]), 'CONDITION');
        this.appendStatementInput('THEN');
        this.setNextStatement(true);
        this.setColour(IF_COLOR);
    }
};

Blockly.Blocks['robovac_if_else'] = {
    init: function() {
        this.setPreviousStatement(true);
        this.appendDummyInput()
            .appendField('falls')
            .appendField(new Blockly.FieldDropdown([
                ['vorne', 'IN_FRONT'],
                ['links', 'ON_THE_LEFT'],
                ['rechts', 'ON_THE_RIGHT']
            ]), 'DIRECTION')
            .appendField(new Blockly.FieldDropdown([
                ['Wand', 'WALL'],
                ['keine Wand', 'NO_WALL'],
                ['dreckiges Feld', 'DIRTY_SQUARE'],
                ['sauberes Feld', 'CLEAN_SQUARE']
            ]), 'CONDITION');
        this.appendStatementInput('THEN');
        this.appendDummyInput().appendField('sonst');
        this.appendStatementInput('ELSE');
        this.setNextStatement(true)
        this.setColour(IF_COLOR);
    }
};

Blockly.Blocks['robovac_move'] = {
    init: function() {
        this.setPreviousStatement(true);
        this.appendDummyInput()
            .appendField('gehe 1 Feld vorwärts');
        this.setNextStatement(true);
        this.setColour(SIMPLE_BLOCKS_COLOR);
    }
};

Blockly.Blocks['robovac_rotate'] = {
    init: function() {
        this.setPreviousStatement(true);
        this.appendDummyInput()
            .appendField('drehe')
            .appendField(new Blockly.FieldDropdown([
                ['90° nach links', 'TURN_LEFT'],
                ['90° nach rechts', 'TURN_RIGHT'],
                ['180°', 'TURN_BACK']
            ]), 'ANGLE');
        this.setNextStatement(true);
        this.setColour(SIMPLE_BLOCKS_COLOR);
    }
};
